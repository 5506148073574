import React, { useState, useRef, useEffect } from 'react';
import { Icon } from '../../components';
import { useLocation, } from 'react-router-dom';
import CRReportForm from './components/CRReportForm';
import useStore from '../../../uiState';
import { Toast } from 'primereact/toast';
import { Dropdown } from 'primereact/dropdown';
import config from '../../../../../config';


function ReportIcon({ rightPanelClosedState, setRightPanelClosed }) {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const studyInstanceUID = params.get('StudyInstanceUIDs');
  const [showCRReportForm, setShowCRReportForm] = useState(false);
  const [modalities, setModalities] = useState([]);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [templateReportData, setTemplateReportData] = useState<any>();
  const [selectedReport, setSelectedReport] = useState<any>();
  const [reportData, setReportData] = useState<any>();
  const [doctorData, setDoctorData] = useState<any>();
  const [reportType, setReportType] = useState();
  const reportpayload = useStore(state => state.reportpayload);
  const setReportPayload = useStore(state => state.setReportPayload);
  const toast = useRef<Toast>(null);
  const dropdownWrapperRef = useRef(null);
  const [selectedModality, setSelectedModality] = useState(null);

  const baseURL = config.API_BASE_URL;

  useEffect(() => { }, [reportpayload]);

  useEffect(() => {
    const handleStorageEvent = event => {
      if (event.key === 'my-shared-store') {
        const newState = JSON.parse(event.newValue);
        useStore.setState(newState);
        fetchTemplateData();
        window.location.reload();
      }
    };
    window.addEventListener('storage', handleStorageEvent);
    return () => {
      window.removeEventListener('storage', handleStorageEvent);
    };
  }, []);

  useEffect(() => {
    const handleClickOutside = event => {
      if (dropdownWrapperRef.current && !dropdownWrapperRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    fetchTemplateData();
  }, []);

  const fetchTemplateData = async () => {
    const payload = {
      form: null,
      condition: {
        studyId: studyInstanceUID,
      },
    };

    await fetch(`${baseURL}/Report/GetReportTemp`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload),
    })
      .then(response => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error('Failed to save content');
        }
      })
      .then(data => {
        const firstTemplate = data.templateData[0];
        setTimeout(() => {
          setSelectedReport(firstTemplate);
        }, 1000);
        setTemplateReportData(data);
        setReportData(data.reportData);
        setModalities(data.templateData);
        setReportType(data.reportData[0].reportStatus);
        // let repdata = data.reportData;
        // let drdata = data.doctorData;
        // let selectedDrData = drdata.filter((a) => a.name == repdata.assignedTo);
        // console.log("selectedDrData", selectedDrData, drdata, repdata);

        setDoctorData(data.doctorData)
      })
      .catch(error => {
        console.error('Error fetching content:', error);
      });
  };

  const handleSaveReport = async (patientName: any, report: any, appuserId: any, clientId: any, templateId: any, iswindowOpen: boolean = false) => {
    if (!selectedReport?.reportId) {
      const payload = {
        name: patientName,
        report: report,
        appuserId: appuserId,
        clientId: clientId,
        templateId: selectedReport?.id,
        reportId: studyInstanceUID,
      };

      try {
        const response = await fetch(`${baseURL}/ReportDetail/Add`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(payload),
        });

        if (response.ok) {
          if (!iswindowOpen) {
            const repdata = await response.json();
            setSelectedReport(repdata);
            toast.current?.show({
              severity: 'success',
              summary: 'Success',
              detail: 'Report Added Successfully',
              life: 3000,
              className: 'bg-green-200 text-dark p-3 border border-secondary',
            });
            fetchTemplateData();
            setShowCRReportForm(false);
            setIsDropdownOpen(false);
          } else {
            fetchTemplateData();
            setShowCRReportForm(false);
          }
        } else {
          throw new Error('Failed to update report. Please try again later.');
        }
      } catch (error) {
        console.error('Error fetching content:', error);
      }
    } else {
      const payload = {
        id: selectedReport?.id,
        name: patientName,
        report: report,
        appuserId: appuserId,
        clientId: clientId,
        templateId: selectedReport?.templateId,
        reportId: studyInstanceUID,
      };

      try {
        const response = await fetch(`${baseURL}/ReportDetail/Update`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(payload),
        });

        if (response.ok) {
          if (!iswindowOpen) {
            toast.current?.show({
              severity: 'success',
              summary: 'Success',
              detail: 'Report Updated Successfully',
              life: 3000,
              className: 'bg-green-200 text-dark p-3 border border-secondary',
            });
            fetchTemplateData();
            setShowCRReportForm(false);
            setIsDropdownOpen(false);
          } else {
            fetchTemplateData();
            setShowCRReportForm(false);
          }
        } else {
          throw new Error('Failed to update report. Please try again later.');
        }
      } catch (error) {
        console.error('Error updating report : ', error);
      }
    }
  };

  const handleSaveFinalizeReport = async () => {
    const payload = {
      studyUid: studyInstanceUID,
      type: 'Finalized',
    };

    try {
      const response = await fetch(`${baseURL}/Report/UpdateReport`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      });

      if (response.ok) {
        fetchTemplateData();
        setShowCRReportForm(false);
        setIsDropdownOpen(false);
      } else {
        throw new Error('Failed to update report. Please try again later.');
      }
    } catch (error) {
      console.error('Error updating report : ', error);
    }
  };

  const closeDropdown = () => setIsDropdownOpen(false);

  const handleClose = () => {
    setShowCRReportForm(false);
    closeDropdown();
    setRightPanelClosed(true);
  };

  const handleRemove = async (
    patientName: any,
    report: any,
    appuserId: any,
    clientId: any,
    templateId: any
  ) => {

    const payload = {
      id: selectedReport?.id,
      name: patientName,
      report: report,
      appuserId: appuserId,
      clientId: clientId,
      templateId: selectedReport?.templateId,
      reportId: studyInstanceUID,
    };

    try {
      const response = await fetch(`${baseURL}/ReportDetail/DeleteRecord`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      });

      if (response.ok) {
        toast.current?.show({
          severity: 'success',
          summary: 'Success',
          detail: 'Report Deleted Successfully',
          life: 3000,
          className: 'bg-green-200 text-dark p-3 border border-secondary',
        });
        setShowCRReportForm(false);
        fetchTemplateData();
      } else {
        throw new Error('Failed to deleting report. Please try again later.');
      }
    } catch (error) {
      console.error('Error deleting report : ', error);
    }
  };

  const handleIcon1Click = () => {
    setRightPanelClosed(false);
    setShowCRReportForm(true);
  };

  const handleIconClick = () => {
    setIsDropdownOpen(prev => !prev);
  };

  const handleModalityClick = async modality => {
    if (selectedReport) {
      setSelectedReport(modality);
      setIsDropdownOpen(true);
      setRightPanelClosed(false);
      setShowCRReportForm(true);
      setTimeout(() => {
        setIsDropdownOpen(false);
      }, 100);
    } else {
      setTimeout(() => {
        setIsDropdownOpen(false);
      }, 100);
    }
  };

  const selectedModalityTemplate = option => {
    if (option) {
      return (
        <div className="flex items-center bg-black px-3 pb-2 text-left">
          <div className=" text-white">{option.name}</div>
        </div>
      );
    }
    return null;
  };

  const modalityOptionTemplate = option => {
    return (
      <div className="flex items-center bg-black px-3 pb-2 text-left">
        <div className="text-white">{option.name}</div>
      </div>
    );
  };

  const CustomPlaceholder = () => (
    <div className="flex text-center align-middle">
      <Icon
        name="tab-patient-info"
        className="h-8 w-8"
      />
      <span>Select an option</span>
    </div>
  );

  return (
    <>
      <div className="group mx-0.5 flex items-center">
        {selectedReport?.reportId ? (
          <div
            className="flex cursor-pointer items-center justify-center text-center text-lg  font-bold text-white transition"
            onClick={handleIcon1Click}
          >
            <Icon
              name="tab-patient-info"
              className="h-8 w-8"
            />
          </div>
        ) : (
          <div className="flex cursor-pointer items-center justify-center">
            <div
              className="flex cursor-pointer items-center justify-center text-center text-lg  font-bold text-white transition"
              onClick={handleIconClick}
            >
              <Icon
                name="tab-patient-info"
                className="h-8 w-8"
              />
            </div>
            {isDropdownOpen && (
              <div
                ref={dropdownWrapperRef}
                className="custom-dropdown-container"
              >
                <Dropdown
                  onChange={e => {
                    setSelectedModality(e.value);
                    handleModalityClick(e.value);
                  }}
                  options={modalities}
                  optionLabel="name"
                  filter
                  valueTemplate={selectedModalityTemplate}
                  itemTemplate={modalityOptionTemplate}
                  className="w-100 custom-dropdown text-lg"
                  style={{
                    backgroundColor: 'black',
                    color: 'white',
                    border: 'none',
                  }}
                  showClear={false}
                  appendTo="self"
                  panelClassName="bg-black text-white"
                />
              </div>
            )}
          </div>
        )}

        <Toast ref={toast} />
        {showCRReportForm && (
          <CRReportForm
            onSave={handleSaveReport}
            studyInstanceUID={studyInstanceUID}
            reportData={reportData}
            selectedReport={selectedReport}
            rightPanelClosedState={rightPanelClosedState}
            setRightPanelClosed={setRightPanelClosed}
            onClose={handleClose}
            onFinzaliedSave={handleSaveFinalizeReport}
            onRemove={handleRemove}
            reportType={reportType}
            doctorData={doctorData}
          />
        )}
      </div>
    </>
  );
}

export default ReportIcon;
