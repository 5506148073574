import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';
import StudyItem from '../StudyItem';
import ThumbnailList from '../ThumbnailList';
import { StringNumber } from '../../types';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { RiDeleteBin6Fill } from 'react-icons/ri';
import useStore from '../../../uiState';
import { Toast } from 'primereact/toast';
import { useLocation } from 'react-router-dom';
import config from '../../../../../config';
import globalStore from '../../../../../globalstate';

const baseURL = config.API_BASE_URL;

const getTrackedSeries = displaySets => {
  let trackedSeries = 0;
  displaySets.forEach(displaySet => {
    if (displaySet.isTracked) {
      trackedSeries++;
    }
  });

  return trackedSeries;
};

const noop = () => { };

const StudyBrowser = ({
  tabs,
  activeTabName,
  expandedStudyInstanceUIDs,
  onClickTab = noop,
  onClickStudy = noop,
  onClickThumbnail = noop,
  onDoubleClickThumbnail = noop,
  onClickUntrack = noop,
  activeDisplaySetInstanceUIDs,
  servicesManager,
}: withAppTypes) => {
  const { t } = useTranslation('StudyBrowser');
  const { customizationService } = servicesManager?.services || {};
  const [imageData, setImageData] = useState<string[]>([]);
  const [visible, setVisible] = useState<boolean>(false);
  const [selectedImage, setSelectedImage] = useState<string | null>(null);
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
  const [selectedImageData, setSelectedImageData] = useState(null);
  const { payload, setPayload } = useStore();
  const toast = useRef<Toast>(null);
  const [type, setType] = useState<any>();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const studyInstanceUID = params.get('StudyInstanceUIDs');
  const { isKeyImage, setIsKeyImage } = useStore();
  const { showHeader, setShowHeader } = globalStore();

  // useEffect(() => {
  //   fetchFileData();
  // }, [isKeyImage]);

  useEffect(() => {
    let intervalId;

    if (isKeyImage) {
      intervalId = setInterval(() => {
        fetchFileData();
        clearInterval(intervalId);
      }, 700);
    }
    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [isKeyImage]);


  useEffect(() => {
    fetchFileData();
  }, [payload, selectedImageData]);


  useEffect(() => {
    const viewerPage =
      window.location.pathname === '/viewer' &&
      window.location.search.includes('StudyInstanceUIDs');

    localStorage.setItem('studyInstanceUID', studyInstanceUID);

    if (viewerPage) {
      const url = window.location.href;
      const parts = url.split('&t');
      const reConvertId = parts[1];
      if (reConvertId) {
        const ids = window.atob(reConvertId);
        const reConvertIds = window.atob(ids);
        setType(reConvertIds);
      }
    }
  }, []);

  const fetchFileData = async () => {
    const payload = {
      form: null,
      condition: {
        studyUid: studyInstanceUID,
      },
      orderColumns: null,
    };
    try {
      const response = await fetch(`${baseURL}/ReportFile/Search`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      });

      if (!response.ok) {
        throw new Error('Failed to fetch content');
      }
      const data = await response.json();

      const latestData = data.reverse();

      if (Array.isArray(latestData) && latestData.length > 0) {
        latestData.forEach(item => {
          try {
            if (typeof item.dicomFile === 'string' && item.dicomFile.startsWith('https://')) {
              // console.log('dicomFile is already a URL:', item.dicomFile);
            } else if (typeof item.dicomFile === 'string' && item.dicomFile.trim() !== '') {
              const dicomFiles = JSON.parse(item.dicomFile);
              item.dicomFile = dicomFiles
                .map(
                  file => `${baseURL}/ImportFiles/${file.filePath.replace(/\\/g, '/')}`
                )
                .join(', ');
            } else {
              console.warn('dicomFile is empty or invalid for item:', item.id);
              item.dicomFile = '';
            }
          } catch (error) {
            console.error('Error parsing dicomFile:', error);
            item.dicomFile = '';
          }
        });
        setTimeout(() => {
          setImageData(latestData);
        }, 1000);
      } else {
        console.error('No data or invalid data format');
        setTimeout(() => {
          setImageData(latestData);
        }, 1000);
      }
    } catch (error) {
      console.error('Error fetching content:', error);
    }
  };

  const footerContent = (
    <div>
      <Button
        label="Ok"
        icon="pi pi-check"
        onClick={() => setVisible(false)}
        autoFocus
        className="bg-customblue-30 text px-3 py-2 text-white"
      />
    </div>
  );

  const handleDeleteImage = async () => {
    if (!selectedImageData) {
      toast.current?.show({
        severity: 'success',
        summary: 'Success',
        detail: 'No key image selected for deletion',
        life: 3000,
        className: 'bg-green-200 text-dark p-3 border border-secondary',
      });
      return;
    }

    const response = await fetch(`${baseURL}/ReportFile/DeleteRecord`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(selectedImageData),
    });

    if (response.ok) {
      toast.current?.show({
        severity: 'success',
        summary: 'Success',
        detail: 'Key Images Deleted Successfully',
        life: 3000,
        className: 'bg-green-200 text-dark p-3 border border-secondary',
      });
      fetchFileData();
      setIsDialogOpen(false);
      setSelectedImageData(null);
    } else {
      throw new Error('Failed to removed Key Images. Please try again later.');
    }
  };

  const handleUpdateImage = async (updatedItem) => {
    const response = await fetch(`${baseURL}/ReportFile/Update`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(updatedItem),
    });

    if (response.ok) {
      toast.current?.show({
        severity: 'success',
        summary: 'Success',
        detail: 'Key Images Updated Successfully',
        life: 3000,
        className: 'bg-green-200 text-dark p-3 border border-secondary',
      });
      setImageData((prevImageData) =>
        prevImageData.map((item) =>
          item.dicomFile === updatedItem.dicomFile
            ? { ...item, isPrint: updatedItem.isPrint }
            : item
        )
      );
    } else {
      throw new Error('Failed to removed Key Images. Please try again later.');
    }
  };

  const deletefooterContent = (
    <div className=" my-2 mx-2">
      <Button
        label="No"
        className="bg-customblue-30 text p-button-text mx-4 px-3 py-2 text-white"
        icon="pi pi-times"
        onClick={() => setIsDialogOpen(false)}
      />
      <Button
        label="Yes"
        className="text bg-green-600 px-3 py-2 text-white"
        icon="pi pi-check"
        onClick={handleDeleteImage}
        autoFocus
      />
    </div>
  );

  const handleShowHeader = (open: any) => {
    setShowHeader(open);
  }

  const getTabContent = () => {
    const tabData = tabs.find(tab => tab.name === activeTabName);
    return tabData.studies.map(
      ({ studyInstanceUid, date, description, numInstances, modalities, displaySets }) => {
        const isExpanded = expandedStudyInstanceUIDs.includes(studyInstanceUid);
        return (
          <React.Fragment key={studyInstanceUid}>
            <StudyItem
              date={date}
              description={description}
              numInstances={numInstances}
              modalities={modalities}
              trackedSeries={getTrackedSeries(displaySets)}
              isActive={isExpanded}
              onClick={() => {
                onClickStudy(studyInstanceUid);
              }}
              data-cy="thumbnail-list"
            />
            {isExpanded && displaySets && (
              <ThumbnailList
                thumbnails={displaySets}
                activeDisplaySetInstanceUIDs={activeDisplaySetInstanceUIDs}
                onThumbnailClick={onClickThumbnail}
                onThumbnailDoubleClick={onDoubleClickThumbnail}
                onClickUntrack={onClickUntrack}
              />
            )}

            <Toast ref={toast} />

            <div className=" mt-3 flex">
              <h1 className=" px-3 text-sm font-bold text-blue-300">Saved Images</h1>
              <span className="pr-1 text-sm font-bold text-blue-300">({imageData.length})</span>
            </div>

            <div
              className={classnames(
                'group mb-8 flex flex-1 px-3 cursor-pointer select-none flex-col outline-none'
              )}
            >
              <div className="ohif-scrollbar study-min-height overflow-y-hidden bg-black py-5">
                {imageData.length > 0 &&
                  imageData.map((item, index) => (
                    <div
                      key={index}
                      className=" flex items-start justify-between"
                    >
                      {type != 'user' && type == 'doctor' && (
                        <div className='  p-2'>
                          <RiDeleteBin6Fill
                            size={15}
                            className="cursor-pointer text-red-500"
                            onClick={() => {
                              setSelectedImageData(item);
                              setIsDialogOpen(true);
                            }}
                          />

                          <div className="mt-2">
                            <input
                              className="cursor-pointer"
                              type="checkbox"
                              checked={item.isPrint}
                              onChange={(e) => {
                                const updatedItem = { ...item, isPrint: e.target.checked };
                                handleUpdateImage(updatedItem);
                              }}
                            />
                          </div>
                        </div>
                      )}

                      <div
                        className={classnames(
                          'mb-3 h-20  items-center justify-center overflow-hidden rounded-md bg-black text-base text-white',
                          'border-primary-light border-2'
                        )}
                      >
                        <img
                          src={item.dicomFile}
                          alt={`Image ${index}`}
                          height={90}
                          width={95}
                          className="object-contain"
                          crossOrigin="anonymous"
                          onClick={() => {
                            setSelectedImage(item.dicomFile);
                            setVisible(true);
                            handleShowHeader("");
                          }}
                        />
                      </div>
                    </div>
                  ))}
              </div>
            </div>

            {/* Zoom imaged shown */}
            <div>
              <Dialog
                header="Saved Image Preview"
                modal
                visible={visible}
                footer={footerContent}
                style={{ width: '50vw', backgroundColor: '#000' }}
                onHide={() => setVisible(false)}
                closable={true}
              >
                {selectedImage && (
                  <div className="bg-secondary-dark m-0 text-white">
                    <img
                      src={selectedImage}
                      alt="Selected"
                      className="h-full w-full object-contain"
                      crossOrigin="anonymous"
                    />
                  </div>
                )}
              </Dialog>
            </div>

            {/* image delete confirmation  */}
            <Dialog
              header="Confirmation"
              visible={isDialogOpen}
              style={{ width: '30vw', backgroundColor: '#000', color: 'white', margin: '10px' }}
              onHide={() => setIsDialogOpen(false)}
              footer={deletefooterContent}
            >
              <p className="m-4">Are you sure you want to Delete Image ??</p>
            </Dialog>
          </React.Fragment>
        );
      }
    );
  };

  return (
    <React.Fragment>
      {/* TODO Revisit design of LegacyButtonGroup later - for now use LegacyButton for its children. */}
      {/* <div
        className="w-100 border-secondary-light bg-primary-dark flex h-16 flex-row items-center justify-center border-b p-4"
        data-cy={'studyBrowser-panel'}
      >

        <LegacyButtonGroup
          variant="outlined"
          color="secondary"
          splitBorder={false}
        >
          {tabs.map(tab => {
            const { name, label, studies } = tab;
            const isActive = activeTabName === name;
            const isDisabled = !studies.length;
            // Apply the contrasting color for brighter button color visibility
            const classStudyBrowser = customizationService?.getModeCustomization(
              'class:StudyBrowser'
            ) || {
              true: 'default',
              false: 'default',
            };
            const color = classStudyBrowser[`${isActive}`];
            return (
              <LegacyButton
                key={name}
                className={'min-w-18 p-2 text-base text-white'}
                size="initial"
                color={color}
                bgColor={isActive ? 'bg-primary-main' : 'bg-black'}
                onClick={() => {
                  onClickTab(name);
                }}
                disabled={isDisabled}
              >
                {t(label)}
              </LegacyButton>
            );
          })}
        </LegacyButtonGroup>
      </div> */}
      <div className="ohif-scrollbar invisible-scrollbar flex flex-1 flex-col overflow-auto">
        {getTabContent()}
      </div>
    </React.Fragment>
  );
};

StudyBrowser.propTypes = {
  onClickTab: PropTypes.func.isRequired,
  onClickStudy: PropTypes.func,
  onClickThumbnail: PropTypes.func,
  onDoubleClickThumbnail: PropTypes.func,
  onClickUntrack: PropTypes.func,
  activeTabName: PropTypes.string.isRequired,
  expandedStudyInstanceUIDs: PropTypes.arrayOf(PropTypes.string).isRequired,
  activeDisplaySetInstanceUIDs: PropTypes.arrayOf(PropTypes.string),
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      studies: PropTypes.arrayOf(
        PropTypes.shape({
          studyInstanceUid: PropTypes.string.isRequired,
          date: PropTypes.string,
          numInstances: PropTypes.number,
          modalities: PropTypes.string,
          description: PropTypes.string,
          displaySets: PropTypes.arrayOf(
            PropTypes.shape({
              displaySetInstanceUID: PropTypes.string.isRequired,
              imageSrc: PropTypes.string,
              imageAltText: PropTypes.string,
              seriesDate: PropTypes.string,
              seriesNumber: StringNumber,
              numInstances: PropTypes.number,
              description: PropTypes.string,
              componentType: PropTypes.oneOf(['thumbnail', 'thumbnailTracked', 'thumbnailNoImage'])
                .isRequired,
              isTracked: PropTypes.bool,
              /**
               * Data the thumbnail should expose to a receiving drop target. Use a matching
               * `dragData.type` to identify which targets can receive this draggable item.
               * If this is not set, drag-n-drop will be disabled for this thumbnail.
               *
               * Ref: https://react-dnd.github.io/react-dnd/docs/api/use-drag#specification-object-members
               */
              dragData: PropTypes.shape({
                /** Must match the "type" a dropTarget expects */
                type: PropTypes.string.isRequired,
              }),
            })
          ),
        })
      ).isRequired,
    })
  ),
};

export default StudyBrowser;
