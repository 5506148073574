import React, { useCallback, useEffect, useState, createRef, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import html2canvas from 'html2canvas';
import useStore from '../../../uiState';
import { Toast } from 'primereact/toast';
import Typography from '../Typography';
import Input from '../Input';
import Tooltip from '../Tooltip';
import IconButton from '../IconButton';
import Icon from '../Icon';
import Select from '../Select';
import InputLabelWrapper from '../InputLabelWrapper';
import Button, { ButtonEnums } from '../Button';
import config from '../../../../../config';

const baseURL = config.API_BASE_URL;

const VIEWPORT_ID = 'cornerstone-viewport-download-form';

const FILE_TYPE_OPTIONS = [
  {
    value: 'jpg',
    label: 'jpg',
  },
  {
    value: 'png',
    label: 'png',
  },
];

const DEFAULT_FILENAME = 'image';

const REFRESH_VIEWPORT_TIMEOUT = 100;

const ViewportDownloadForm = ({
  activeViewportElement,
  onClose,
  updateViewportPreview,
  enableViewport,
  disableViewport,
  toggleAnnotations,
  loadImage,
  downloadBlob,
  defaultSize,
  minimumSize,
  maximumSize,
  canvasClass,
}) => {
  const { t } = useTranslation('Modals');
  const [filename, setFilename] = useState(DEFAULT_FILENAME);
  const [fileType, setFileType] = useState(['png']);
  const [blobUrl, setBlobUrl] = useState(null);
  const { payload, setPayload } = useStore();
  const toast = useRef(null);
  const [keyImageCount, setKeyImageCount] = useState(0);
  const { isKeyImage, setIsKeyImage } = useStore();
  const [isSaving, setIsSaving] = useState(false);


  const [dimensions, setDimensions] = useState({
    width: defaultSize,
    height: defaultSize,
  });

  const [showAnnotations, setShowAnnotations] = useState(true);

  const [keepAspect, setKeepAspect] = useState(true);
  const [aspectMultiplier, setAspectMultiplier] = useState({
    width: 1,
    height: 1,
  });

  const [viewportElement, setViewportElement] = useState();
  const [viewportElementDimensions, setViewportElementDimensions] = useState({
    width: defaultSize,
    height: defaultSize,
  });

  const [downloadCanvas, setDownloadCanvas] = useState({
    ref: createRef(),
    width: defaultSize,
    height: defaultSize,
  });

  const [viewportPreview, setViewportPreview] = useState({
    src: null,
    width: defaultSize,
    height: defaultSize,
  });

  const [error, setError] = useState({
    width: false,
    height: false,
    filename: false,
  });

  const hasError = Object.values(error).includes(true);

  const refreshViewport = useRef(null);
  const onKeepAspectToggle = () => {
    const { width, height } = dimensions;
    if (!keepAspect) {
      const aspectMultiplier = {
        width: width / height,
        height: height / width,
      };
      setAspectMultiplier(aspectMultiplier);
    }

    setKeepAspect(!keepAspect);
  };

  const [isCanvasReady, setCanvasReady] = useState(false);

  useEffect(() => {
    // Fetch the canvas element using data-viewport-uid
    const canvasElement = document.querySelector(
      '[data-viewport-uid="cornerstone-viewport-download-form"] .cornerstone-canvas'
    );
    if (canvasElement) {
      downloadCanvas.current = canvasElement;
      setCanvasReady(true);
    } else {
      console.error('Canvas element not found');
    }
  }, []);

  const fetchFileData = async () => {
    const studyInstanceUID = localStorage.getItem('studyInstanceUID');

    const payload = {
      form: null,
      condition: {
        studyUid: studyInstanceUID,
      },
      orderColumns: null,
    };

    try {
      const response = await fetch(`${baseURL}/ReportFile/Search`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      });

      if (!response.ok) {
        throw new Error('Failed to fetch content');
      }
      const data = await response.json();
      // console.log("Image data", data, data.length);
      setKeyImageCount(data.length);

    } catch (error) {
      console.error('Error fetching content:', error);
    }
  };

  const downloadImage = () => {
    setIsSaving(true);
    downloadBlob1(
      filename || DEFAULT_FILENAME,
      fileType,
      viewportElement,
      downloadCanvas.ref.current
    );
  };

  const downloadBlob1 = async (filename, fileType, viewportElement, downloadCanvas) => {
    const file = `${filename}.${fileType}`;
    const divForDownloadViewport = document.querySelector(
      `div[data-viewport-uid="${VIEWPORT_ID}"]`
    );

    if (!divForDownloadViewport) {
      console.error('Viewport element not found');
      return;
    }

    try {
      const canvas = await html2canvas(divForDownloadViewport);
      const base64String = canvas.toDataURL(`image/${fileType}`, 1.0);
      const blob = base64ToBlob(base64String, `image/${fileType}`);

      //file upload
      const studyInstanceUID = localStorage.getItem('studyInstanceUID');
      const formData = new FormData();
      formData.append('file', blob, `${filename}.${fileType}`);

      const response = await fetch(`${baseURL}/ReportFile/FileUpload`, {
        method: 'POST',
        body: formData,
      });

      if (!response.ok) {
        throw new Error('Failed to save content');
      }
      const imageResponse = await response.json();
      const imageUrl = JSON.stringify([imageResponse]);

      //add Report
      const payload = {
        dicomFile: imageUrl,
        studyUid: studyInstanceUID,
        isprint: "false"
      };
      setPayload(payload);
      setIsKeyImage(true);

      const addResponse = fetch(`${baseURL}/ReportFile/Add`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      }).then((response) => {
        return response.json();
      }).then((result) => {

        setTimeout(() =>
          fetchFileData(), 700)
        onClose();

        toast.current?.show({
          severity: 'success',
          summary: 'Success',
          detail: 'Key Images Added Successfully',
          life: 3000,
          className: 'bg-green-200 text-dark p-3 border border-secondary',
        });

      })
    } catch (error) {
      toast.current?.show({
        severity: 'error',
        summary: 'Error',
        detail: 'Failed to add Key Images report. Please try again later.',
        life: 3000,
        className: 'bg-red-200 text-dark p-3 border border-secondary',
      });
      console.error('Error generating blob:', error);
    }
  };

  // Helper function to convert base64 to Blob
  const base64ToBlob = (base64, contentType) => {
    const byteCharacters = atob(base64.split(',')[1]);
    const byteArrays = [];
    for (let offset = 0; offset < byteCharacters.length; offset += 512) {
      const slice = byteCharacters.slice(offset, offset + 512);
      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }
    return new Blob(byteArrays, { type: contentType });
  };

  /**
   * @param {object} value - Input value
   * @param {string} dimension - "height" | "width"
   */
  const onDimensionsChange = (value, dimension) => {
    const oppositeDimension = dimension === 'height' ? 'width' : 'height';
    const sanitizedTargetValue = value.replace(/\D/, '');
    const isEmpty = sanitizedTargetValue === '';
    const newDimensions = { ...dimensions };
    const updatedDimension = isEmpty ? '' : Math.min(sanitizedTargetValue, maximumSize);

    if (updatedDimension === dimensions[dimension]) {
      return;
    }

    newDimensions[dimension] = updatedDimension;

    if (keepAspect && newDimensions[oppositeDimension] !== '') {
      newDimensions[oppositeDimension] = Math.round(
        newDimensions[dimension] * aspectMultiplier[oppositeDimension]
      );
    }

    // In current code, keepAspect is always `true`
    // And we always start w/ a square width/height
    setDimensions(newDimensions);

    // Only update if value is non-empty
    if (!isEmpty) {
      setViewportElementDimensions(newDimensions);
      setDownloadCanvas(state => ({
        ...state,
        ...newDimensions,
      }));
    }
  };

  const error_messages = {
    width: 'The minimum valid width is 100px.',
    height: 'The minimum valid height is 100px.',
    filename: 'The file name cannot be empty.',
  };

  const renderErrorHandler = errorType => {
    if (!error[errorType]) {
      return null;
    }

    return (
      <Typography
        className="mt-2 pl-1"
        color="error"
      >
        {error_messages[errorType]}
      </Typography>
    );
  };

  const validSize = useCallback(
    value => (value >= minimumSize ? value : minimumSize),
    [minimumSize]
  );

  const loadAndUpdateViewports = useCallback(async () => {
    const { width: scaledWidth, height: scaledHeight } = await loadImage(
      activeViewportElement,
      viewportElement,
      dimensions.width,
      dimensions.height
    );

    toggleAnnotations(showAnnotations, viewportElement, activeViewportElement);

    const scaledDimensions = {
      height: validSize(scaledHeight),
      width: validSize(scaledWidth),
    };

    setViewportElementDimensions(scaledDimensions);
    setDownloadCanvas(state => ({
      ...state,
      ...scaledDimensions,
    }));

    const {
      dataUrl,
      width: viewportElementWidth,
      height: viewportElementHeight,
    } = await updateViewportPreview(viewportElement, downloadCanvas.ref.current, fileType);

    setViewportPreview(state => ({
      ...state,
      src: dataUrl,
      width: validSize(viewportElementWidth),
      height: validSize(viewportElementHeight),
    }));
  }, [
    loadImage,
    activeViewportElement,
    viewportElement,
    dimensions.width,
    dimensions.height,
    toggleAnnotations,
    showAnnotations,
    validSize,
    updateViewportPreview,
    downloadCanvas.ref,
    fileType,
  ]);

  useEffect(() => {
    enableViewport(viewportElement);

    return () => {
      disableViewport(viewportElement);
    };
  }, [disableViewport, enableViewport, viewportElement]);

  useEffect(() => {
    if (refreshViewport.current !== null) {
      clearTimeout(refreshViewport.current);
    }

    refreshViewport.current = setTimeout(() => {
      refreshViewport.current = null;
      loadAndUpdateViewports();
    }, REFRESH_VIEWPORT_TIMEOUT);
  }, [
    activeViewportElement,
    viewportElement,
    showAnnotations,
    dimensions,
    loadImage,
    toggleAnnotations,
    updateViewportPreview,
    fileType,
    downloadCanvas.ref,
    minimumSize,
    maximumSize,
    loadAndUpdateViewports,
  ]);

  useEffect(() => {
    const { width, height } = dimensions;
    const hasError = {
      width: width < minimumSize,
      height: height < minimumSize,
      filename: !filename,
    };

    setError({ ...hasError });
  }, [dimensions, filename, minimumSize]);

  return (
    <div>
      {/* <Typography variant="h6">
        {t('Please specify the dimensions, filename, and desired type for the output image.')}
      </Typography> */}

      {/* <div className="mt-6 flex flex-col">
       <div className="mb-4 w-full">
          <Input
            data-cy="file-name"
            value={filename}
            onChange={evt => setFilename(evt.target.value)}
            label={t('File Name')}
          />
          {renderErrorHandler('filename')}
        </div>

         <div className="flex">
         <div className="flex w-1/3">
            <div className="flex grow flex-col">
              <div className="w-full">
                <Input
                  type="number"
                  min={minimumSize}
                  max={maximumSize}
                  label={t('Image width (px)')}
                  value={dimensions.width}
                  onChange={evt => onDimensionsChange(evt.target.value, 'width')}
                  data-cy="image-width"
                />
                {renderErrorHandler('width')}
              </div>
              <div className="mt-4 w-full">
                <Input
                  type="number"
                  min={minimumSize}
                  max={maximumSize}
                  label={t('Image height (px)')}
                  value={dimensions.height}
                  onChange={evt => onDimensionsChange(evt.target.value, 'height')}
                  data-cy="image-height"
                />
                {renderErrorHandler('height')}
              </div>
            </div>

            <div className="mt-8 flex items-center">
              <Tooltip
                position="right"
                content={keepAspect ? 'Dismiss Aspect' : 'Keep Aspect'}
              >
                <IconButton
                  onClick={onKeepAspectToggle}
                  size="small"
                  rounded="full"
                >
                  <Icon name={keepAspect ? 'link' : 'unlink'} />
                </IconButton>
              </Tooltip>
            </div>
          </div>

          <div className="border-secondary-dark ml-6 w-1/4 border-l pl-6">
            <div>
              <InputLabelWrapper
                sortDirection="none"
                label={t('File Type')}
                isSortable={false}
                onLabelClick={() => {}}
              >
                <Select
                  className="mt-2 text-white"
                  isClearable={false}
                  value={fileType}
                  data-cy="file-type"
                  onChange={value => {
                    setFileType([value.value]);
                  }}
                  hideSelectedOptions={false}
                  options={FILE_TYPE_OPTIONS}
                  placeholder="File Type"
                />
              </InputLabelWrapper>
            </div>
            <div className="mt-4 ml-2">
              <label
                htmlFor="show-annotations"
                className="flex items-center"
              >
                <input
                  id="show-annotations"
                  data-cy="show-annotations"
                  type="checkbox"
                  className="mr-2"
                  checked={showAnnotations}
                  onChange={event => setShowAnnotations(event.target.checked)}
                />
                <Typography>{t('Show Annotations')}</Typography>
              </label>
            </div>
          </div>
        </div>
      </div>*/}

      <div
        className="bg-secondary-dark border-secondary-primary w-max-content relative min-w-full rounded"
        data-cy="image-preview"
      >
        <Toast ref={toast} />

        <div className="mt-4 flex items-center justify-between">
          {/* <div className="flex">
            <Typography variant="h6">{t('Image preview')}</Typography>
          </div> */}

          <div className="bg-secondary-dark absolute bottom-0 z-10 flex w-full text-white">
            <Button
              name="cancel"
              type={ButtonEnums.type.secondary}
              onClick={onClose}
            >
              {t('Cancel')}
            </Button>
            <Button
              className="ml-2"
              disabled={hasError || isSaving}
              onClick={downloadImage}
              type={ButtonEnums.type.primary}
              name={'Save'}
            >
              {t('Save')}
            </Button>
          </div>
        </div>

        {activeViewportElement && (
          <div
            className="mx-auto my-2"
            style={{
              height: viewportElementDimensions.height,
              width: viewportElementDimensions.width,
              overflow: 'scroll',
            }}
            ref={ref => setViewportElement(ref)}
          ></div>
        )}
        {!activeViewportElement && (
          <Typography className="mt-4">{t('Active viewport has no displayed image')}</Typography>
        )}
      </div>
    </div>
  );
};

export default ViewportDownloadForm;
