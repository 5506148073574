import { create } from 'zustand';
import { persist } from 'zustand/middleware';

const globalStore = create(
  persist(
    set => ({
      showHeader: "",
      setShowHeader: newshowHeader => set({ showHeader: newshowHeader }),
    }),
    {
      name: 'my-shared-store',
      partialize: state => ({ reportpayload: state.reportpayload }),
    }
  )
);

export default globalStore;
